export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>How we can help you</h2>

          <div>
            <p style={{ fontWeight: 400, color: '#888' }}>Cutting grass around commercial buildings is every bit as important as mowing
              residential yards. In fact, because of the added liability of operating a business,
              commercial lawn mowing is a necessity that you can’t ignore. Unfortunately, too
              many service providers try and charge a premium for lawn mowing for businesses.
              For what you wind up paying them, you could have purchased a riding lawn mower
              and do it yourself! But luckily for you, OBX Lawn Mowing remains
              the affordable and efficient solutions for all your lawn care needs.
              When you need quality grass maintenance at lowers pricing, we achieve
              the best results each time! Don’t let higher costs of other services
              prevent your business from looking its best. Instead, allow us to
              maintain your yards for less today!</p>
          </div>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.title}-${i}`} className='col-sm-4'>
                {' '}
                <i className={d.icon}></i>
                <h3>{d.title}</h3>
                <p style={{ fontSize: 14, color: '888' }}>{d.text}</p>
              </div>
            ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
