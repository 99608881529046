export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
            Proper landscape maintenance takes more than just
            a little raking and mowing. But if you’re like
            many people, you just don’t have the time to care
            for your plants the way that you should.
            Overgrown bushes and shrubs, tree leaves,
            and scraggly grass all make for a long time
            spent outside. And when you don’t have the
            right tools or equipment, you could waste
            hours without results! Instead, you can always
            depend on OBX Lawn Mowing for
            complete landscape maintenance solutions.
            We keep your yard looking its best for less!
            Call today for your best choice in affordable
            landscaping services.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-4'>
                {' '}
                {/* <i className={d.icon}></i> */}
                <div className='service-desc' style={{ borderRadius: 10, padding: 10, minHeight: 200 }}>
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))
            : 'loading'}

          <b style={{ fontSize: 20 }}>Our average lawn mowing prices are between $30 and $80 per visit</b>
        </div>
      </div>
    </div>
  )
}
